import React, { Component, Suspense } from 'react'

import './App.css';
import { HashRouter, Routes, Route } from "react-router-dom";
import bt from './css/css/bootstrap.min.css'
import ico from './css/css/all.min.css'
import stylCss from './css/scss/style.css'
import mixin from './css/scss/mixins.css'

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Page404 = React.lazy(() => import('./pages/Page404'))

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

function App() {
  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
}

export default App;
